<template>
  <StyledModal :visible="visible" :title="`${isAdd ? '新建' : '编辑'}文章`" @close="close" @submit="submit" :submitLoading="submitLoading" width="700px">
    <el-form class="form_box" ref="formRef" :model="formModel.form" label-width="120px">
      <el-form-item label="文章标题" prop="textTitle" :rules="[{ required: true, message: '请输入文章标题' }]">
        <el-input v-model="formModel.form.textTitle" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="封面" prop="textTitleImage" :rules="[{ required: true, message: '请添加封面' }]">
        <div>
          <div><ImgUpload v-model="formModel.form.textTitleImage" :limit="1" /></div>
          <div>建议尺寸 340*240</div>
        </div>
      </el-form-item>
      <el-form-item label="文章位置" prop="textSeat" :rules="[{ required: true, message: '请选择文章位置' }]">
        <FetchSelect
          v-model="formModel.form.textSeat"
          placeholder="文章位置"
          api="/web/sys/code/getSysCodeByParam"
          :postData="{ codeParam: 'SOFT_TEXT_SEAT' }"
          textKey="codeValue"
          valueKey="codeKey"
        />
      </el-form-item>
      <el-form-item label="文章分类" prop="textClass" :rules="[{ required: true, message: '请选择文章分类' }]">
        <FetchSelect
          v-model="formModel.form.textClass"
          placeholder="文章分类"
          api="/web/sys/code/getSysCodeByParam"
          :postData="{ codeParam: 'SOFT_TEXT_CLASS' }"
          textKey="codeValue"
          valueKey="codeKey"
        />
      </el-form-item>
      <!-- 其实是 跳转类型 -->
      <div>
        <el-form-item label="文章类型" prop="textType" :rules="[{ required: true, message: '请选择文章类型' }]">
          <FetchSelect
            v-model="formModel.form.textType"
            placeholder="文章类型"
            api="/web/sys/code/getSysCodeByParam"
            :postData="{ codeParam: 'SOFT_TEXT_TYPE' }"
            textKey="codeValue"
            valueKey="codeKey"
            @change="formModel.form.refTargetUrl = ''"
          />
        </el-form-item>
        <!-- 公众号 -->
        <el-form-item v-if="formModel.form.textType === '01'" label="地址" prop="refTargetUrl" :rules="[{ required: true, message: '请输入地址' }]">
          <el-input class="input_refTargetUrl" v-model="formModel.form.refTargetUrl" placeholder="请输入" type="textarea"></el-input>
        </el-form-item>
        <!-- 活动页面 -->
        <el-form-item v-if="formModel.form.textType === '02'" label="活动页面" prop="refTargetUrl" :rules="[{ required: true, message: '请选择活动页面' }]">
          <FetchSelect
            v-model="formModel.form.refTargetUrl"
            placeholder="选择活动页面"
            api="/web/system/active/getActivePaging"
            :postData="{
              page: 1,
              rows: 9999,
            }"
            :isPaging="true"
            textKey="activeName"
            valueKey="activeCode"
          />
        </el-form-item>
      </div>
      <el-form-item
        label="排序"
        prop="textSeq"
        :rules="[
          { required: true, message: '请输入排序' },
          { type: 'number', min: 0, max: 99999, message: '请输入0-99999之间的数字' },
        ]"
      >
        <el-input v-model.number="formModel.form.textSeq" placeholder="0-99999之间"></el-input>
      </el-form-item>
      <el-form-item
        label="阅读数"
        prop="readingNum"
        :rules="[
          { required: false, message: '请输入阅读数' },
          { type: 'number', min: 0, max: 999999, message: '请输入0-999999之间的数字' },
        ]"
      >
        <el-input v-model.number="formModel.form.readingNum" placeholder="0-999999之间"></el-input>
      </el-form-item>
      <el-form-item
        label="转发数"
        prop="fwdNum"
        :rules="[
          { required: false, message: '请输入转发数' },
          { type: 'number', min: 0, max: 999999, message: '请输入0-999999之间的数字' },
        ]"
      >
        <el-input v-model.number="formModel.form.fwdNum" placeholder="0-999999之间"></el-input>
      </el-form-item>
    </el-form>
  </StyledModal>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import { cloneDeep } from 'lodash'
import StyledModal from '@/components/modal/StyledModal/index.vue'
import ImgUpload from '@/components/ImgUpload'
import FetchSelect from '@/components/FetchSelect/index.vue'
import DetailEditor from '@/components/DetailEditor'
import { validateFormRef } from '@/common/utils_element'
import request from '@/utils/request'
import { trimAll } from '@/utils/utils'

const defaultForm = {
  textTitle: '',
  textTitleImage: '',
  textClass: '',
  textType: '',
  refTargetUrl: '',
  textSeq: '',
  textSeat: '',
  readingNum: '',
  fwdNum: '',
}

export default {
  emits: ['successAdd', 'successEdit'],
  setup(_, ctx) {
    const visible = ref(false)
    const submitLoading = ref(false)

    const formRef = ref(null)
    const formModel = reactive({
      form: cloneDeep(defaultForm),
    })

    const record = reactive({ v: null })
    const isAdd = computed(() => (record.v ? false : true))

    function open(recordParam) {
      visible.value = true
      if (recordParam) {
        record.v = cloneDeep(recordParam)
        renderForm(cloneDeep(recordParam))
      }
    }

    function close() {
      visible.value = false
      record.v = null
      formModel.form = cloneDeep(defaultForm)
    }

    // form回显
    function renderForm(data) {
      // 处理noticeContent // 因为saas商城和在线问诊 这俩项目的 这个组件，数据格式不一样
      const arr = JSON.parse(data.noticeContent ?? '[]')
      const noticeContentArr = arr.map((item) => {
        let type
        if (item.type == 1) {
          type = 'text'
        } else if (item.type == 2) {
          type = 'image'
        }
        return {
          id: item.id,
          type,
          content: item.value,
        }
      })
      const noticeContent = JSON.stringify(noticeContentArr)
      // 处理noticeContent end

      formModel.form = {
        ...data,
        noticeContent,
      }
    }

    // 提交
    async function submit() {
      await validateFormRef(formRef)

      // 处理noticeContent // 因为saas商城和在线问诊 这俩项目的 这个组件，数据格式不一样
      const arr = JSON.parse(formModel.form.noticeContent ?? '[]')
      const noticeContentArr = arr.map((item) => {
        let type
        if (item.type == 'text') {
          type = 1
        } else if (item.type == 'image') {
          type = 2
        }
        return {
          id: item.id,
          type,
          value: item.content,
        }
      })
      const noticeContent = JSON.stringify(noticeContentArr)
      // 处理noticeContent end

      const postData = {
        ...(isAdd.value ? {} : { textCode: record.v.textCode }),
        noticeContent,
        ...formModel.form,
        readingNum: trimAll(formModel.form.readingNum?.toString() || '') || '0',
        fwdNum: trimAll(formModel.form.fwdNum?.toString() || '') || '0',
      }
      submitLoading.value = true
      request({
        url: isAdd.value ? '/web/system/softText/createSoftText' : '/web/system/softText/updateSoftText',
        data: postData,
      })
        .finally(() => {
          submitLoading.value = false
        })
        .then(() => {
          ctx.emit(isAdd.value ? 'successAdd' : 'successEdit')
          close()
        })
    }

    return {
      visible,
      isAdd,
      submitLoading,
      open,
      close,
      submit,
      //
      formRef,
      formModel,
    }
  },
  components: { StyledModal, FetchSelect, ImgUpload, DetailEditor },
}
</script>

<style lang="less">
.input_refTargetUrl {
  textarea {
    min-height: 170px !important;
  }
}
</style>

<style lang="less" scoped>
.form_box {
  width: 90%;
}
</style>
